<template>
  <div class="productBox">
    <div class="leftCard">
      <div class="leftTit">产品分销服务</div>
      <div class="leftDesc">
        我们始终专注美国市场的数字化服饰供应链平台，致力于助力美国本土的SMB通过“0库存”“一件代发模式”帮助美国本土网红、创作者、店主等快速提升销售能力与收益。团队成员分布在纽约、北京和广州，是以技术为核心驱动的国际化团队。目前已整合超过300家优质供应商和第三方品牌，
        同时旗下直签与合作海量优质美国本土达人， 始终保持该领域发展的领先地位。
        中国广州和美国都设有自建仓储，从品控到仓配进行全链条的严控把关，以保证交付给用户最佳的服务体验，拉近海外本土卖家与中国优质供应链的距离。
      </div>
    </div>
    <div class="rightCard">
      <div class="procudtList">
        <div class="productItem productItemOrange">
          <img
            style="width: 102px; height: 120px"
            src="https://video.wahool.com/b_website/home/productVector1.png"
            alt=""
          />
        </div>
        <div class="productItem productItemOrange"></div>
      </div>
      <div class="procudtList">
        <div class="proChildBox">
          <div class="productItem productItemBalck"></div>
          <div class="productItem productItemOrange">
            <img
              style="width: 120px; height: 120px"
              src="https://video.wahool.com/b_website/home/productVector2.png"
              alt=""
            />
          </div>
          <div class="productItem productItemOrange">
            <img
              style="width: 120px; height: 101px"
              src="https://video.wahool.com/b_website/home/productVector3.png"
              alt=""
            />
          </div>
          <div class="productItem productItemGray"></div>
          <div class="productItem productItemGray"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
.productBox {
  padding: 202px 0 220px;
  font-family: 'Lantinghei SC';
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .leftCard {
    max-width: 400px;
    margin-right: 90px;
    .leftTit {
      margin-bottom: 41px;
      font-weight: 800;
      font-size: 40px;
      line-height: 56px;
      color: #111111;
    }
    .leftDesc {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #666666;
    }
  }
  .rightCard {
    max-width: 1070px;
    // max-width: 56%;
    overflow: hidden;
    .procudtList {
      display: flex;
      overflow: hidden;
      .proChildBox {
        max-width: 1180px;
        // width: 61%;
        display: flex;
      }
      .productItem {
        width: 220px;
        height: 220px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 20px;
        img {
          display: flex;
        }
      }
      .productItemOrange {
        background: #fb5d43;
      }
      .productItemBalck {
        background: #111111;
      }
      .productItemGray {
        background: #f2f2f2;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .productBox {
    padding-left: 50px;
  }
}
</style>
