<template>
  <div class="modeBox">
    <div class="modeItem">
      <div class="modeChild">
        <div class="textBox">
          <div class="tit">趋势</div>
          <div class="desc">
            全球零售市场加速“线下到线上”的转型，电商零售在全球零售市场中的渗透率也得到进一步提升。
          </div>
        </div>
      </div>
    </div>
    <div class="modeItem">
      <div class="modeChild">
        <div class="textBox">
          <div class="tit">机遇</div>
          <div class="desc">
            伴随着海外社交和直播电商的快速发展与“直播带货”的兴起，大量本土中小卖家、网红、创作者、精品店主对供应链需求亟待满足，相关服务却仍是相对空白。
          </div>
        </div>
      </div>
    </div>
    <div class="modeItem">
      <div class="modeChild">
        <div class="textBox">
          <div class="tit">模式</div>
          <div class="desc">
            “一件代发”并非新概念，国内有20年积累的1688平台，国外也有不少“代发货”的插件或软件，以独立站首选建站平台、应用生态布局丰富的Shopify为例，其
            “代发货”分类下的搜索结果可高达275个。“一件代发Dropshipping”成为了海外本土卖家创业的最佳捷径。
          </div>
        </div>
      </div>
    </div>
    <div class="btns" @click="toPartnerPage">合作洽谈</div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
const toPartnerPage = () => {
  router.push({
    path: '/partnerPage',
  });
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 1920px) {
  .modeItem {
    background-position: none;
    background-size: cover !important;
    .desc {
    }
  }
}
@media screen and(max-width:1920px) {
  .modeItem {
    background-position: center center !important;
    background-size: cover;
    .desc {
    }
  }
}
@media screen and(min-width:1200px) and(max-width:1440px) {
  .desc {
    padding: 0 16px;
    font-size: 16px;
    line-height: 22px;
  }
}
@media screen and(min-width:1440px) {
  .desc {
    font-size: 20px;
    line-height: 28px;
  }
}
.modeBox {
  width: 100%;
  height: 900px;
  font-family: 'Lantinghei SC';
  display: flex;
  position: relative;
  .modeItem {
    width: 33%;
    height: 900px;
    position: relative;
    .modeChild {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      .textBox {
        display: flex;
        margin-top: 300px;
        max-width: 400px;
        flex-direction: column;
        align-items: center;
        .tit {
          font-size: 40px;
          line-height: 56px;
          font-weight: bold;
          color: #ffffff;
        }
        .desc {
          width: 100%;
          margin-top: 40px;
          font-weight: 400;

          color: #fff;
        }
      }
    }
  }
  .modeItem:nth-child(1) {
    background: url('https://video.wahool.com/b_website/home/mode1.png')
      no-repeat;
  }
  .modeItem:nth-child(2) {
    background: url('https://video.wahool.com/b_website/home/mode3.png')
      no-repeat;
  }
  .modeItem:nth-child(3) {
    width: 34% !important;
    background: url('https://video.wahool.com/b_website/home/mode2.png')
      no-repeat;
    background-size: cover;
  }
  .btns {
    position: absolute;
    left: 50%;
    bottom: 110px;
    transform: translate(-50%, 0);
    width: 140px;
    height: 40px;
    background: #fb5d43;
    border-radius: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    cursor: pointer;
    margin-top: 40px;
    color: #fff;
    cursor: pointer;
  }
}
</style>
