<template>
  <div class="cardListBox">
    <div class="cardList">
      <div class="cardItem">
        <div class="cardTopImg">
          <img
            src="https://video.wahool.com/b_website/home/cardList1.png"
            alt=""
          />
        </div>
        <div class="cardTit">
          <div class="title">精准触达</div>
          <div class="title">美国本土达人号</div>
        </div>
      </div>
      <div class="cardItem">
        <div class="cardTopImg">
          <img
            src="https://video.wahool.com/b_website/home/cardList2.png"
            alt=""
          />
        </div>
        <div class="cardTit">
          <div class="title">多种合作模式</div>
          <div class="title">现采现结佣金结算</div>
        </div>
      </div>
      <div class="cardItem">
        <div class="cardTopImg">
          <img
            src="https://video.wahool.com/b_website/home/cardList3.png"
            alt=""
          />
        </div>
        <div class="cardTit">
          <div class="title">美国品牌</div>
          <div class="title">自主发货</div>
        </div>
      </div>
      <div class="cardItem">
        <div class="cardTopImg">
          <img
            src="https://video.wahool.com/b_website/home/cardList4.png"
            alt=""
          />
        </div>
        <div class="cardTit">
          <div class="title">美国本土</div>
          <div class="title">客服专业服务</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
@media screen and(min-width:1200px) and (max-width: 1440px) {
  .cardList {
    padding: 0 5%;
  }
}
@media screen and(min-width:1440px) {
  .cardList {
    padding: 0 10%;
  }
}
.cardListBox {
  width: 100%;
  background: #fb5d43;
  display: flex;
  justify-content: center;
  font-family: 'Lantinghei SC';
  padding: 280px 0 260px 0;
  .cardList {
    // min-width: 1280px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cardItem {
      .cardTopImg {
        width: 220px;
        height: 220px;
        background: #ffffff;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: flex;
        }
      }
      .cardTit {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #fff;
          font-weight: 600;
          font-size: 28px;
          line-height: 39px;
        }
      }
    }
  }
}
</style>
