<template>
  <div class="stepBox">
    <div class="cardBox">
      <div class="topImg">
        <img
          src="https://video.wahool.com/b_website/home/phoneIcon.png"
          alt=""
        />
        <div class="topTit">入驻优势</div>
      </div>
      <div class="conList">
        <div class="conItem" v-for="(item, index) in contentList" :key="index">
          <div class="numView">{{ item.num }}</div>
          <div class="textView" v-for="val in item.conList" :key="val">
            {{ val.title }}
          </div>
        </div>
      </div>
      <div class="btns" @click="toPartnerPage">合作洽谈</div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
const toPartnerPage = () => {
  router.push({
    path: '/partnerPage',
  });
};
const contentList = [
  {
    num: 1,
    conList: [{ title: '无保证金 无入驻费' }, { title: '无推广费无销售佣金' }],
  },
  {
    num: 2,
    conList: [{ title: '面向欧美市场销售' }, { title: '采购额度稳步增长' }],
  },
  {
    num: 3,
    conList: [
      { title: '现采现结' },
      { title: '卖家无需担心账期问题' },
      { title: '非质量问题0退货' },
    ],
  },
  {
    num: 4,
    conList: [{ title: '完善的供应链体系' }, { title: '供应商轻松出货' }],
  },
  {
    num: 5,
    conList: [
      { title: '系统化管理和培养体系' },
      { title: '您与Wahool共同成长' },
    ],
  },
];
</script>
<style lang="less" scoped>
.stepBox {
  width: 100%;
  padding: 100px 0;
  font-family: 'Lantinghei SC';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .cardBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .topImg {
      margin-left: 100px;
      position: relative;
      img {
        width: 1087px;
        height: 1050px;
        object-fit: cover;
      }
      .topTit {
        font-weight: 700;
        font-size: 40px;
        line-height: 56px;
        color: #111111;
        position: absolute;
        left: 410px;
      }
    }

    .conList {
      width: 100%;
      display: flex;
      // padding: 0 100px;
      padding: 0 5%;
      box-sizing: border-box;
      justify-content: space-between;
      margin-top: 100px;
      .conItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        .numView {
          border-radius: 50%;
          width: 42px;
          height: 42px;
          box-sizing: border-box;
          background: #ffffff;
          border: 4px solid #fb5d43;
          box-shadow: 10px 10px 10px rgba(251, 93, 67, 0.1);
          color: #fb5d43;
          font-family: 'Gotham';
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 40px;
        }
        .textView {
          width: 220px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: #333333;
        }
      }
    }
    .btns {
      width: 140px;
      height: 40px;
      background: #fb5d43;
      border-radius: 20px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 40px;
      cursor: pointer;
      margin-top: 40px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
